import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputField, SelectField, transformErrorForForm } from './form'
import { BaseTag, Tag } from '../types'

const TagSchema = Yup.object().shape({
  nome: Yup.string().required().label('Nome'),
  tipo: Yup.string().required().label('Tipo'),
})

interface Props {
  tag?: Tag
  save: (tag: Tag) => Promise<unknown>
}

export const initialThemeValues: BaseTag = {
  nome: '',
  tipo: '',
}

export default function TagForm({ tag, save }: Props) {
  return (
    <Formik
      enableReinitialize
      onSubmit={(tag, { setErrors }) =>
        save(tag).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={TagSchema}
      initialValues={(tag ?? initialThemeValues) as Tag}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-6">
              <Field
                required
                label={`Nome`}
                name={`nome`}
                component={InputField}
              />
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Tipo`}
                name={`tipo`}
                component={SelectField}
              >
                <option value="">Seleziona</option>
                <option value="tema">Tema</option>
                <option value="luogo">Luogo</option>
                <option value="attivita">Attività</option>
                <option value="mezzo_di_trasporto">Mezzo di trasporto</option>
              </Field>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
