import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import RegistiList from './RegistiList'
import RegistaCreate from './RegistaCreate'
import RegistaEdit from './RegistaEdit'
export default function Registi() {
  return (
    <Routes>
      <Route index element={<RegistiList/>} />
      <Route path="new" element={<RegistaCreate />} />
      <Route path=":id" element={<RegistaEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
