import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useCreateTag } from '../../hooks/tags'
import TagForm from '../../components/TagForm'

export default function TagCreate() {
  const createTag = useCreateTag()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/tags">Tags</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <TagForm
          save={async (tag) => {
            const newTag = await createTag.mutateAsync(tag)
            navigate(`/tags/${newTag.id}`)
          }}
        />
      </div>
    </Page>
  )
}
