import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import FontiList from './FontiList'
import FonteCreate from './FonteCreate'
import FonteEdit from './FonteEdit'

export default function Fonti() {
  return (
    <Routes>
      <Route index element={<FontiList/>} />
      <Route path="new" element={<FonteCreate />} />
      <Route path=":id" element={<FonteEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
