import { UseQueryOptions } from '@tanstack/react-query'

export type QueryOnlyOptions = Omit<
  UseQueryOptions<any, any, any, any>,
  'queryKey' | 'queryFn'
> & { params?: Record<string, any> }

interface FormDataEncoderConfig {
  fileFields: string[]
  excludeFields?: string[]
  walker?(key: string, value: any, fm: FormData): boolean
}

export function serializeQueryParams(params: Record<string, any>) {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    const value = params[key]
    if (Array.isArray(value)) {
      value.forEach((v) => {
        searchParams.append(key, v)
      })
    } else {
      searchParams.append(key, value)
    }
  })
  return searchParams
}

export function makeFormDataEncoder(confg: FormDataEncoderConfig) {
  function formDataEncoder(data: Record<string, any>) {
    const fm = new FormData()
    Object.keys(data).forEach((k) => {
      const value = data[k]
      if (confg.walker && confg.walker(k, value, fm)) {
        return
      }
      if (confg.excludeFields && confg.excludeFields.includes(k)) {
        return
      }
      if (confg.fileFields.includes(k)) {
        if (value instanceof File) {
          fm.append(k, value)
        } else if (typeof value === 'string') {
          if (!value.startsWith('http')) {
            fm.append(k, value)
          }
        } else if (value === null) {
          fm.append(k, '')
        }
      } else {
        if (Array.isArray(value)) {
          if (k === 'comuni') {
            value.forEach((v) => {
              fm.append('comuni', v)
            })
          } else if (k === 'regioni') {
            value.forEach((v) => {
              fm.append('regioni', v)
            })
          } else if (k === 'tags') {
            value.forEach((v) => {
              fm.append('tags', v)
            })
          } else if (k === 'registi') {
            value.forEach((v) => {
              fm.append('registi', v)
            })
          } else {
            fm.append(k, JSON.stringify(value))
          }
        } else if (typeof value === 'object' && value !== null) {
          fm.append(k, JSON.stringify(value))
        } else if (value === null) {
          fm.append(k, '')
        } else {
          fm.append(k, String(value))
        }
      }
    })
    return fm
  }

  return formDataEncoder
}
