import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import TagCreate from './TagCreate'
import TagsList from './TagsList'
import TagEdit from './TagEdit'

export default function Tags() {
  return (
    <Routes>
      <Route index element={<TagsList/>} />
      <Route path="new" element={<TagCreate />} />
      <Route path=":id" element={<TagEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
