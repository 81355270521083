import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import RegistaForm from '../../components/RegistaForm'
import { useCreateRegista } from '../../hooks/registi'

export default function RegistaCreate() {
  const createRegista = useCreateRegista()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/registi">Registi</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <RegistaForm
          save={async (regista) => {
            const newRegista = await createRegista.mutateAsync(regista)
            navigate(`/registi/${newRegista.id}`)
          }}
        />
      </div>
    </Page>
  )
}
