import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import Page from '../../components/Page'
import Paginator from '../../components/Paginator'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { useQsFilters } from '../../hooks/filters'
import { Tag } from '../../types'
import { useDeleteTag, useTag, useTags, useUnificaTags } from '../../hooks/tags'
import { TIPO_TAG } from '../../consts'
import { Field, Formik } from 'formik'
import { MultiSelectField, ReactSelectField } from '../../components/form'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '',
})

export default function TagsList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useTags(filters)
  const deleteTag = useDeleteTag()
  const [modalRm, modalRmActions] = useModalTrigger<Tag>()
  const [modalUnifica, modalUnificaActions] = useModalTrigger()

  const filtersTags = {
    page_size: 1000,
  }

  const { data: tagsFull } = useTags(filtersTags)

  const tagsOptions =
    tagsFull?.results.map((tag) => ({
      value: tag.id,
      label: tag.nome + ' - ' + TIPO_TAG[tag.tipo],
    })) ?? []

  const unificaTags = useUnificaTags()

  return (
    <Page padded>
      <Header>
        <div className="d-flex align-items-end">
          <h2 className="m-0">Tags</h2>
          <div className="counter ms-2 mb-1">
            <small>
              {data!.count}
              {' / '}
              {data!.total_count}
            </small>
          </div>
        </div>
        <div className="d-flex">
          <Button
            color="primary"
            className="me-2"
            outline
            onClick={modalUnificaActions.toggle}
          >
            Unifica
          </Button>
          <Link className="btn btn-primary" to="/tags/new">
            <i className="bi bi-plus-circle me-2"></i> Aggiungi
          </Link>
        </div>
      </Header>
      <div className="d-flex justify-content-between mt-3 mb-3 align-items-center">
        <input
          placeholder="Cerca"
          className="form-control w-auto"
          type="text"
          value={uiFilters.search}
          onChange={(e) =>
            setFiltersDebounced({ search: e.target.value, page: 1 })
          }
        />
      </div>
      <StickyTable className="flex-1">
        <thead>
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
                page: 1,
              })
            }
          >
            <tr>
              <th>#</th>
              <th>
                <div className="d-flex align-items-center">
                  Nome
                  <SortControl field="nome" />
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Tipo
                  <SortControl field="tipo" />
                </div>
              </th>
            </tr>
          </Sorter>
        </thead>
        <tbody>
          {data!.results.map((tag) => (
            <tr key={tag.id}>
              <td>{tag.id}</td>
              <td>
                <Link to={`/tags/${tag.id}`}>{tag.nome}</Link>
              </td>
              <td>{TIPO_TAG[tag.tipo]}</td>
            </tr>
          ))}
        </tbody>
      </StickyTable>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3 text-dark">
              Sei sicuro di voler eliminare il tag
              {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteTag.isLoading}
            onClick={async () => {
              await deleteTag.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={modalUnifica.isOpen}
        toggle={modalUnificaActions.toggle}
      >
        <ModalHeader toggle={modalUnificaActions.toggle}>Unifica</ModalHeader>
        <Formik
          initialValues={{
            tag_to_keep: null,
            tags_to_delete: [],
          }}
          onSubmit={async (values) => {
            if (!values.tag_to_keep || values.tags_to_delete.length === 0)
              return
            await unificaTags
              .mutateAsync({
                tag_to_keep: values.tag_to_keep,
                tags_to_delete: values.tags_to_delete,
              })
              .then(() => {
                modalUnificaActions.toggle()
              })
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Field
                    component={ReactSelectField}
                    name="tag_to_keep"
                    label="Tag da mantenere"
                    placeholder="Seleziona il tag da mantenere"
                    options={tagsOptions}
                  />
                </div>
                <div>
                  <Field
                    component={MultiSelectField}
                    name="tags_to_delete"
                    placeholder="Seleziona i tag da eliminare"
                    label="Tag da eliminare"
                    options={tagsOptions.filter(
                      (option) => option.value !== values.tag_to_keep
                    )}
                    isMulti
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  onClick={modalUnificaActions.toggle}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button className="btn btn-success" type="submit">
                  <i className="bi bi-save-fill me-1"></i>
                  Salva
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
      <div className="position-relative">
        <Paginator
          count={data!.count}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </Page>
  )
}
