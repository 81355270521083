export const PAGE_SIZE = 100

export const TIPO_TAG = {
  tema: 'Tema',
  luogo: 'Luogo',
  attivita: 'Attività',
  mezzo_di_trasporto: 'Mezzo di trasporto',
} as Record<string, string>

export const COLORE_MAP = {
  bn: 'Bianco e nero',
  col: 'Colore',
  'bn/col': 'Bianco e nero e colore',
} as Record<string, string>

export const FORMATO_MAP = {
  cinegiornale: 'Cinegiornale',
  cortometraggio: 'Cortometraggio',
  'inchiesta televisiva': 'Inchiesta televisiva',
  lungometraggio: 'Lungometraggio',
  mediometraggio: 'Mediometraggio',
  reportage: 'Reportage',
  '8mm': '8mm',
  '16mm': '16mm',
  'Super8': 'Super8',
  '9,5mm': '9,5mm',
} as Record<string, string>
