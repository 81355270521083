import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import {
  InputField,
  transformErrorForForm,
} from './form'
import { BaseProduzione, Produzione } from '../types'

const ProduzioneSchema = Yup.object().shape({
  nome: Yup.string().required().label('Nome'),
})

interface Props {
  produzione?: Produzione
  save: (produzione: Produzione) => Promise<unknown>
}

export const initialThemeValues: BaseProduzione = {
  nome: '',
}

export default function ProduzioneForm({ produzione, save }: Props) {
  return (
    <Formik
      enableReinitialize
      onSubmit={(regista, { setErrors }) =>
        save(regista).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={ProduzioneSchema}
      initialValues={(produzione ?? initialThemeValues) as Produzione}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label={`Nome`}
                name={`nome`}
                component={InputField}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
