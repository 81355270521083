import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import {
  CheckBoxField,
  FileField,
  InputField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import { BaseVideo, Video } from '../types'
import { COLORE_MAP, FORMATO_MAP } from '../consts'
import AutoCompleteField from './form/fields'

const VideoSchema = Yup.object().shape({
  titolo: Yup.string().required().label('Titolo'),
})

interface Props {
  video?: Video
  save: (video: Video) => Promise<unknown>
}

export const initialThemeValues: BaseVideo = {
  titolo: '',
  youtube_url: '',
  fonte_url: '',
  colore: '',
  durata: '',
  formato: '',
  sinossi: '',
  persone_note: '',
  mare_terra: false,
  interno_esterno: false,
  mito_racconto: false,
  velocita_lentezza: false,
  produzione: '',
  immagine: '',
  note: '',
  fonte: '',
  attivo: true,
  memoryscapes: false,
}

export default function VideoForm({ video, save }: Props) {
  const checkIfIsYoutubeOrVimeo = (url: string) => {
    if (url.includes('youtube')) {
      return videoUrlYoutubeForEmbed(url)
    } else if (url.includes('vimeo')) {
      return videoUrlVimeoForEmbed(url)
    }
  }

  const videoUrlYoutubeForEmbed = (url: string) => {
    const urlSplitted = url.split('v=')
    const videoId = urlSplitted[urlSplitted.length - 1]
    const removeOtherParams = videoId.split('&')[0]
    return `https://www.youtube.com/embed/${removeOtherParams}`
  }

  const videoUrlVimeoForEmbed = (url: string) => {
    const urlSplitted = url.split('/')
    const videoId = urlSplitted[urlSplitted.length - 2]
    const videoH = urlSplitted[urlSplitted.length - 1].replace(
      '?share=copy',
      ''
    )

    return `https://player.vimeo.com/video/${videoId}?h=${videoH}`
  }
  return (
    <Formik
      enableReinitialize
      onSubmit={(video, { setErrors }) =>
        save(video).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={VideoSchema}
      initialValues={(video ?? initialThemeValues) as Video}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12">
              <Field
                required
                label={`Titolo`}
                name={`titolo`}
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              {/* {show youtube preview if video is present} */}
              {video?.youtube_url && (
                <div className="embed-responsive embed-responsive-16by9">
                  {/* <iframe
                    className="embed-responsive-item"
                    src={`${video?.youtube_url}`}
                    allowFullScreen
                    title="video"
                  ></iframe> */}
                  {/* check if is youtube video or vimeo */}
                  {video?.youtube_url && (
                    <iframe
                      className="embed-responsive-item"
                      src={checkIfIsYoutubeOrVimeo(video?.youtube_url)}
                      allowFullScreen
                      title="video"
                    ></iframe>
                  )}
                 
                </div>
              )}
              <Field
                label={`Youtube url`}
                name={`youtube_url`}
                component={InputField}
              />
            </div>
            <div className="col-md-12">
              <Field
                label={`Fonte url`}
                name={`fonte_url`}
                component={InputField}
              />
            </div>
            <div className="col-md-4 mt-2">
              <AutoCompleteField
                label="Produzione"
                name="produzioni"
                url="/api/admin/produzioni/options/"
                keyQuery="options-produzioni"
                isMulti
                value={values.produzioni}
              />
            </div>
            <div className="col-md-4 mt-2">
              <AutoCompleteField
                label="Fonte"
                name="fonti"
                isMulti
                url="/api/admin/fonti/options/"
                keyQuery="options-fonti"
                value={values.fonti}
              />
            </div>
            <div className="col-md-4 mt-2">
              <Field
                label={`Immagine`}
                name={`immagine`}
                component={FileField}
              />
            </div>
            <div className="col-md-3 mt-2">
              <Field name="anno" label="Anno" component={InputField} />
            </div>
            <div className="col-md-3 mt-2">
              <Field name="colore" label="Colore" component={SelectField}>
                <option value="">Seleziona</option>
                {Object.entries(COLORE_MAP).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-3 mt-2">
              <Field name="durata" label="Durata" component={InputField} />
            </div>
            <div className="col-md-3 mt-2">
              <Field name="formato" label="Formato" component={SelectField}>
                <option value="">Seleziona</option>
                {Object.entries(FORMATO_MAP).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-3 mt-2">
              <Field
                label={`Mare/Terra`}
                name={`mare_terra`}
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-3 mt-2">
              <Field
                label={`Interno/Esterno`}
                name={`interno_esterno`}
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-3 mt-2">
              <Field
                label={`Mito/Racconto`}
                name={`mito_racconto`}
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-3 mt-2">
              <Field
                label={`Velocità/Lentezza`}
                name={`velocita_lentezza`}
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-12 mt-2">
              <AutoCompleteField
                label="Regioni"
                name="regioni"
                url="/api/admin/regioni/options/"
                keyQuery="options-regioni"
                value={values.regioni}
                isMulti
              />
            </div>
            <div className="col-md-12 mt-2">
              <AutoCompleteField
                label="Comuni"
                name="comuni"
                url="/api/admin/comuni/options/"
                keyQuery="options-comuni"
                value={values.comuni}
                isMulti
              />
            </div>
            <div className="col-md-12 mt-2">
              <AutoCompleteField
                label="Registi"
                name="registi"
                url="/api/admin/registi/options/"
                keyQuery="options-registi"
                value={values.registi}
                isMulti
              />
            </div>
            <div className="col-md-12 mt-2">
              <AutoCompleteField
                label="Tags"
                name="tags"
                url="/api/admin/tags/options/"
                keyQuery="options-tags"
                value={values.tags}
                isMulti
              />
            </div>
            <div className="col-md-12 mt-2">
              <Field
                label={`Sinossi`}
                name={`sinossi`}
                component={TextareaField}
                rows={5}
              />
            </div>
            <div className="col-md-12 mt-2">
              <Field
                label={`Persone note`}
                name={`persone_note`}
                component={TextareaField}
              />
            </div>
            <div className="col-md-12 mt-2">
              <Field label={`Note`} name={`note`} component={TextareaField} />
            </div>
            <div className="col-md-12 mt-2">
              <Field
                label={`Attivo`}
                name={`attivo`}
                component={CheckBoxField}
              />
            </div>
            <div className="col-md-12 mt-2">
              <Field
                label={`Memoryscapes`}
                name={`memoryscapes`}
                component={CheckBoxField}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn btn-success"
            >
              <i className="text-white me-1 bi bi-save-fill"></i> Salva
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
