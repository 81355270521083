import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Regista, PaginatedDJResponse, Fonte, Produzione } from '../types'

export function useProduzioni(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Produzione>>(
    ['produzioni', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/produzioni/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useProduzione(id: number) {
  return useQuery<Produzione>(
    ['produzioni', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/produzioni/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteProduzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/produzioni/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['produzioni'])
      },
    }
  )
}

export function useCreateProduzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Produzione) =>
        axios
          .post(`/api/admin/produzioni/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Produzione)
    ),
    {
      onSuccess(savedProduzione) {
        client.invalidateQueries(['produzioni'])
        client.setQueryData(['produzione', savedProduzione.id], savedProduzione)
      },
    }
  )
}

export function useUpdateProduzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (produzione: Produzione) =>
        axios
          .put(`/api/admin/produzioni/${produzione.id}/`, produzione, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Fonte)
    ),
    {
      onSuccess(savedProduzione) {
        client.invalidateQueries(['produzioni'])
        client.setQueryData(['produzione', savedProduzione.id], savedProduzione)
      },
    }
  )
}

export function useUnificaProduzioni() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          produzione_to_keep,
          produzioni_to_delete,
        }: {
          produzione_to_keep: number
          produzioni_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/produzioni/unifica/`,
              { produzione_to_keep, produzioni_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Produzione[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['produzioni'])
      },
    }
  )
}
