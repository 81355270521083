import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { Tag } from '../../types'
import { useDeleteTag, useTag, useUpdateTag } from '../../hooks/tags'
import TagForm from '../../components/TagForm'

export default function TagEdit() {
  const { id } = useParams()
  const { data: tag } = useTag(Number(id))
  const deleteTag = useDeleteTag()
  const updateTag = useUpdateTag()
  const [modalRm, modalRmActions] = useModalTrigger<Tag>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/tags">Tags</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {tag?.nome}
            </li>
          </ol>
        </nav>
        <button
          className="btn  btn-danger"
          onClick={() => modalRmActions.open(tag)}
        >
          <i className="bi bi-trash3-fill"></i> Elimina
        </button>
      </Header>

      <div className="py-3 overflow-y-auto flex-1">
        <TagForm
          tag={tag}
          save={(tag) =>
            updateTag.mutateAsync(tag).then(() =>
              toast.success('Tag salvato con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare il tag {modalRm.value.nome} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btnoutline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteTag.isLoading}
            onClick={async () => {
              await deleteTag.mutateAsync(modalRm.value!.id).then(() => {
                modalRmActions.close()
                navigate('/tags')
              })
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
