import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import Auth from './Auth'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import DataProvider from './DataProvider'
import ErrorBoundary from './ErrorBoundary'
import { RequireAuth, RequireGuest } from './routing'
import RecoverPassword from './pages/RecoverPassword'
import ResetPassword from './pages/ResetPassword'
import Login from './pages/Login'
import { ToastContainer } from 'react-toastify'
import Users from './pages/Users'
import ActivateAccount from './pages/ActivateAccount'
import { User } from './types'
import Profile from './pages/Profile'
import Registi from './pages/Registi'
import Tags from './pages/Tags'
import Video from './pages/Video'
import Fonti from './pages/Fonti'
import Produzioni from './pages/Produzioni'

function checkUserIsStaff(user: User) {
  if (!user.is_staff) {
    return '/'
  }
}

export default function App() {
  return (
    <ErrorBoundary>
      <DataProvider>
        <Auth>
          <Router>
            <Routes>
              {/* GUEST */}
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="password-recover" element={<RecoverPassword />} />
                <Route
                  path="password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route
                  path="activate-account/:token"
                  element={<ActivateAccount />}
                />
              </Route>
              {/* AUTH */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route index element={<Navigate to="/profile" />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={checkUserIsStaff}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="users/*" element={<Users />} />
              </Route>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="registi/*" element={<Registi />} />
                <Route path="fonti/*" element={<Fonti />} />
                <Route path='produzioni/*' element={<Produzioni />} />
                <Route path="tags/*" element={<Tags />} />
                <Route path="video/*" element={<Video />} />
              </Route>
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Router>
        </Auth>
      </DataProvider>
      <ToastContainer autoClose={3000} draggable={false} />
    </ErrorBoundary>
  )
}
