import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Regista, PaginatedDJResponse } from '../types'

export function useRegisti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Regista>>(
    ['registi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/registi/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useRegista(id: number) {
  return useQuery<Regista>(
    ['registi', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/registi/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteRegista() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/registi/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['registi'])
      },
    }
  )
}

export function useCreateRegista() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Regista) =>
        axios
          .post(`/api/admin/registi/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Regista)
    ),
    {
      onSuccess(savedRegista) {
        client.invalidateQueries(['registi'])
        client.setQueryData(['regista', savedRegista.id], savedRegista)
      },
    }
  )
}

export function useUpdateRegista() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (regista: Regista) =>
        axios
          .put(`/api/admin/registi/${regista.id}/`, regista, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Regista)
    ),
    {
      onSuccess(savedRegista) {
        client.invalidateQueries(['registi'])
        client.setQueryData(['regista', savedRegista.id], savedRegista)
      },
    }
  )
}

export function useUnificaRegisti() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          regista_to_keep,
          registi_to_delete,
        }: {
          regista_to_keep: number
          registi_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/registi/unifica/`,
              { regista_to_keep, registi_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Regista[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['registi'])
      },
    }
  )
}
