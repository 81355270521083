import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { PaginatedDJResponse, Tag } from '../types'

export function useTags(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Tag>>(
    ['tags', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/tags/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useTag(id: number) {
  return useQuery<Tag>(
    ['tag', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/tags/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteTag() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/tags/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['tags'])
      },
    }
  )
}

export function useCreateTag() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Tag) =>
        axios
          .post(`/api/admin/tags/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Tag)
    ),
    {
      onSuccess(savedTag) {
        client.invalidateQueries(['tags'])
        client.setQueryData(['tag', savedTag.id], savedTag)
      },
    }
  )
}

export function useUpdateTag() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (tag: Tag) =>
        axios
          .put(`/api/admin/tags/${tag.id}/`, tag, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Tag)
    ),
    {
      onSuccess(savedTag) {
        client.invalidateQueries(['tags'])
        client.setQueryData(['tag', savedTag.id], savedTag)
      }
    }
  )
}

export function useUnificaTags() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          tag_to_keep,
          tags_to_delete,
        }: {
          tag_to_keep: number
          tags_to_delete: number[]
        }) =>
          axios
            .post(
              `/api/admin/tags/unifica/`,
              { tag_to_keep, tags_to_delete },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Tag[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['tags'])
      },
    }
  )
}
