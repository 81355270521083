import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import ProduzioneCreate from './ProduzioneCreate'
import ProduzioneEdit from './ProduzioneEdit'
import ProduzioniList from './ProduzioniList'

export default function Produzioni() {
  return (
    <Routes>
      <Route index element={<ProduzioniList/>} />
      <Route path="new" element={<ProduzioneCreate />} />
      <Route path=":id" element={<ProduzioneEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
