import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link, NavLink } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import { User } from '../../types'
import styles from './SideBar.module.scss'

export default function SideBar() {
  const { user } = useAuthUser<User>()
  const { logout } = useAuthActions()
  const [logoutModal, actionsLogout] = useModalTrigger()
  return (
    <div className={styles.SideBar}>
      <div className={styles.Title}>
        <Link to="/" className="no-link">
          <h2>
            Archivi Del Sud <br />
            Amministrazione
          </h2>
        </Link>
      </div>
      <div className="mt-5">
        <div className={styles.ContainerMenuItem}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.activeLink : 'no-link'
            }
            to="/fonti"
          >
            <div className={styles.MenuItem}>
              {/* Icon for regista persona */}
              <i className="bi me-1 bi-journal-text"></i> Fonti
            </div>
          </NavLink>
        </div>
        <div className={styles.ContainerMenuItem}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.activeLink : 'no-link'
            }
            to="/produzioni"
          >
            <div className={styles.MenuItem}>
              {/* Icon for regista persona */}
              <i className="bi me-1 bi-film"></i> Produzioni
            </div>
          </NavLink>
        </div>
        <div className={styles.ContainerMenuItem}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.activeLink : 'no-link'
            }
            to="/registi"
          >
            <div className={styles.MenuItem}>
              {/* Icon for regista persona */}
              <i className="bi me-1 bi-person-fill"></i> Registi
            </div>
          </NavLink>
        </div>
        <div className={styles.ContainerMenuItem}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.activeLink : 'no-link'
            }
            to="/tags"
          >
            <div className={styles.MenuItem}>
              {/* Icon for tags */}
              <i className="bi me-1 bi-tags-fill"></i> Tags
            </div>
          </NavLink>
        </div>
        <div className={styles.ContainerMenuItem}>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.activeLink : 'no-link'
            }
            to="/video"
          >
            <div className={styles.MenuItem}>
              {/* Icon for video */}
              <i className="bi me-1 bi-camera-reels-fill"></i> Video
            </div>
          </NavLink>
        </div>
        {user!.is_staff && (
          <div className={styles.ContainerMenuItem}>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeLink : 'no-link'
              }
              to="/users"
            >
              <div className={styles.MenuItem}>
                <i className="bi me-1 bi-people-fill"></i> Utenti
              </div>
            </NavLink>
          </div>
        )}
      </div>
      <div className={`${styles.Profile} mt-auto`}>
        <div className="d-flex justify-content-between">
          <div>
            <Link className={styles.Name} to="/profile">
              {user!.name}
            </Link>
          </div>
          <div
            className={styles.Logout}
            onClick={() => {
              actionsLogout.open()
            }}
          >
            <i className="bi bi-box-arrow-right" />
          </div>
        </div>
        <div className={styles.Email}>{user!.email}</div>
      </div>
      <Modal
        centered
        isOpen={logoutModal.isOpen}
        toggle={actionsLogout.toggle}
        onClosed={actionsLogout.onClosed}
      >
        <ModalHeader toggle={actionsLogout.toggle}>Logout</ModalHeader>
        <ModalBody>
          <div className="pt-3 pb-3">
            Sei sei sicuro di voler uscire dall'applicativo?
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={actionsLogout.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-primary"
            onClick={async () => {
              logout()
            }}
          >
            <i className="bi bi-box-arrow-right me-1"></i> Esci
          </button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
